<template>
	<div>
        <LoadingScreen v-if="loading || loadingState" />
		<ContentHeader title="Pergantian Shift" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
	              <div class="card-header">
	                <h3 class="card-title"><div style="#007bff">Pergantian Shift</div></h3>

	                <div class="card-tools">
	                </div>
	              </div>
	              <div class="card-body row">
	              	<div class="col-lg-2"></div>
	              	<div class="col-lg-8">
                        <div id="buka_shift" v-if="shift == null">
                            <h1 class="text-center mb-5">Buka Shift</h1>
                            <el-form :model="formState" ref="formRef" label-position="left" label-width="200px">
                                <el-form-item label="Jadwal Shift" prop="jadwal_shift_id" :rules="[ { required: true, message: 'Pilih Jadwal Shift', trigger: change } ]">
                                    <el-select v-model="formState.jadwal_shift_id" style="width: 100%;" placeholder="Pilih Jadwal Shift">
                                        <el-option v-for="(row, index) in jadwalShift" :key="index" :value="row.id" :label="row.nama"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="saldo_awal" required label="Saldo Awal">
                                    <el-input-number v-model="formState.saldo_awal" :min="0" style="width: 100%;"></el-input-number>
                                </el-form-item>
                                <el-form-item label="User Aktif">
                                    <el-input type="text" v-model="user.name" disabled style="width: 100%;"></el-input>
                                </el-form-item>
                                <el-button type="primary" class="mt-4" @click="prosesSubmit" :loading="loading">Simpan</el-button>
                            </el-form>
                        </div>
                        <div v-if="shift">
                            <h1 class="text-center mb-5">Tutup Shift</h1>
                            <div v-if="toggleSaldoKasir">
                                <el-form v-model="shift" label-position="left" label-width="200px">
                                    <el-form-item label="Saldo Kasir"  >
                                        <el-input-number v-model="shift.saldo_kasir" :min="0" style="width: 100%;"></el-input-number>
                                    </el-form-item>
                                    <el-button type="primary" @click="dialogVisible = true">Proses</el-button>
                                </el-form>
                            </div>

                            <div v-if="!toggleSaldoKasir">
                                <el-form :model="shift" ref="formRefTutupSaldo" label-width="200px" label-position="left">
                                    <el-form-item label="Saldo Awal">
                                        <el-input-number v-model="shift.saldo_awal" disabled :min="0" style="width: 100%;"></el-input-number>
                                    </el-form-item>
                                    <el-form-item label="Hasil Pembayaran Klinik">
                                        <el-input-number v-model="shift.hasil_pembayaran_klinik" disabled :min="0" style="width: 100%;"></el-input-number>
                                    </el-form-item>
                                    <el-form-item label="Pembayaran Piutang Klinik">
                                        <el-input-number v-model="shift.pembayaran_piutang_klinik" disabled :min="0" style="width: 100%;"></el-input-number>
                                    </el-form-item>
                                    <el-form-item label="Total Pendapatan">
                                        <el-input-number v-model="shift.total_pendapatan" disabled :min="0" style="width: 100%;"></el-input-number>
                                    </el-form-item>
                                    <el-form-item label="Retur Pembayaran Klinik">
                                        <el-input-number v-model="shift.retur_pembayaran_klinik" disabled :min="0" style="width: 100%;"></el-input-number>
                                    </el-form-item>
                                    <el-form-item label="Total Pengeluaran">
                                        <el-input-number v-model="shift.total_pengeluaran" disabled :min="0" style="width: 100%;"></el-input-number>
                                    </el-form-item>
                                    <el-form-item label="Saldo Akhir">
                                        <el-input-number v-model="shift.saldo_akhir" disabled :min="0" style="width: 100%;"></el-input-number>
                                    </el-form-item>
                                    <el-form-item label="Saldo Kasir">
                                        <el-input-number v-model="shift.saldo_kasir" disabled :min="0" style="width: 100%;"></el-input-number>
                                    </el-form-item>
                                    <el-form-item label="Selisih Saldo">
                                        <el-input-number v-model="shift.selisih_saldo" disabled :min="0" style="width: 100%;"></el-input-number>
                                    </el-form-item>
                                    <el-form-item label="Di Serahkan Kepada">
                                        <el-select v-model="shift.karyawan_id" placeholder="Pilih Karyawan" style="width: 100%;">
                                            <el-option v-for="(row, index) in karyawan" :key="index" :value="row.id" :label="row.nama_lengkap"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Catatan">
                                        <el-input type="textarea" v-model="shift.catatan" style="width: 100%;" placeholder="Masukkan Catatan"></el-input>
                                    </el-form-item>
                                    <el-button type="primary" class="mt-4" @click="submitTutupShift" :loading="loading">Proses</el-button>
                                </el-form>
                            </div>
                        </div>
	              	</div>
	              	<div class="col-lg-2"></div>
	              </div>
	              <!-- /.card-body -->
	              <div class="card-footer" style="display: block;">
	              </div>
	              <!-- /.card-footer-->

                    <el-dialog
                        v-model="dialogVisible"
                        title="Konfirmasi"
                        width="30%"
                    >
                        <span>Anda yakin Saldo Kasir sudah benar?</span>
                        <template #footer>
                        <span class="dialog-footer">
                            <el-button @click="dialogVisible = false">Cancel</el-button>
                            <el-button type="primary" @click="submitSaldoKasir"
                            >Confirm</el-button
                            >
                        </span>
                        </template>
                    </el-dialog>
            	</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'
import LoadingScreen from '@/components/LoadingScreen'

import { ref, reactive, computed } from 'vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import store from '@/store'

// Data
const loading      = ref(false)
const user         = computed(() => store.getters['auth/user'])
const jadwalShift  = computed(() => store.getters['jadwalShift/getJadwalShift'])
const shift        = computed(() => store.getters['shift/getData'])
const loadingState = computed(() => store.getters['shift/getLoading'])
const karyawan     = computed(() => store.getters['karyawan/getKaryawan'])

// Fetch Data
store.dispatch('jadwalShift/fetchJadwalShift', user.value.cabang_id)
store.dispatch('shift/fetchDataOpen', user.value)
store.dispatch('karyawan/fetchAll', user.value.cabang_id)

// Data Form
const formRef           = ref()
const formRefTutupSaldo = ref()
const formState         = reactive({
    cabang_id            : user.value.cabang_id,
    user_id              : user.value.id,
	jadwal_shift_id      : null,
    saldo_awal           : 0
})

const toggleSaldoKasir = ref(true)
const dialogVisible    = ref(false)

const submitSaldoKasir = () => {
    toggleSaldoKasir.value = false
    dialogVisible.value    = false
    shift.value.selisih_saldo = shift.value.saldo_kasir - shift.value.saldo_awal 
    console.log(shift.value)
}

const prosesSubmit = () => {
    formRef.value.validate((valid) => {
        if (valid) {
            loading.value = true
            axios.post(`api/shift/create`, formState)
            .then(() => {
            	Swal.fire({
            	  title: 'Berhasil!',
            	  text: 'Berhasil Menambahkan Data',
            	  icon: 'success',
            	  showConfirmButton: false,
            	  timer: 1500
            	})
            	loading.value = false
                formState.jadwal_shift_id = null
                formState.saldo_awal = null
                store.dispatch('shift/fetchDataOpen', user.value)
            })
            .catch(() => {
            	Swal.fire({
            	  title: 'Gagal!',
            	  text: 'Gagal menambahkan data',
            	  icon: 'error',
            	  showConfirmButton: false,
            	  timer: 1500
            	})	
            	loading.value = false
            })
            
        } else {
            return false
        }
    })
}

const submitTutupShift = () => {
    formRefTutupSaldo.value.validate((valid) => {
        if (valid) {
            loading.value = true
            axios.put(`api/shift/update/${shift.value.id}`, shift.value)
            .then(() => {
            	Swal.fire({
            	  title: 'Berhasil!',
            	  text: 'Berhasil memperbaharui Data',
            	  icon: 'success',
            	  showConfirmButton: false,
            	  timer: 1500
            	})
            	loading.value = false
                store.dispatch('shift/fetchDataOpen', user.value)
            })
            .catch(() => {
            	Swal.fire({
            	  title: 'Gagal!',
            	  text: 'Gagal memperbaharui data',
            	  icon: 'error',
            	  showConfirmButton: false,
            	  timer: 1500
            	})	
            	loading.value = false
            })
            
        } else {
            return false
        }
    })
}
</script>